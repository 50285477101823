import React, { useState } from 'react';
import { Text } from '../../styledComponents/Text';
import { MainContainer } from '../../styledComponents/MainContainer';
import { Content } from '../../styledComponents/Content';
import { Container } from '../../styledComponents/Container';
import { GlobalStyle } from '../../styledComponents/GlobalStyle';
import { Input } from '../../styledComponents/Input';
import { SearchButton } from '../../styledComponents/Button';  // Use the yellow search button
import { LoadingText } from '../../styledComponents/LoadingText';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

function Home() {
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSearch = () => {
        setLoading(true);
        fetch("/download_snapchat_story", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-api-key": process.env.REACT_APP_API_KEY
            },
            body: JSON.stringify({ username })
        })
        .then(res => res.json())  // Parse the response as JSON
        .then(data => {
            setLoading(false);
            
            if (data.stories || data.highlights) {  // Check if stories or highlights exist
                navigate(`/profile/${username}`, { state: { stories: data.stories, highlights: data.highlights } });
            } else {
                alert(data.error || "No stories or highlights found.");
            }
        })
        .catch(error => {
            setLoading(false);
            console.error("Error:", error);
            alert("An error occurred, please try again.");
        });
    };

    return (
        <>
            <GlobalStyle />
                    <div style={{
    // backgroundColor: "#FFD700", // Gold/yellow banner
    backgroundColor: "yellow",
    color: "#000",
    textAlign: "center",
    padding: "15px",
    fontSize: "18px",
    fontWeight: "bold"
}}>
    🚀 New Feature: <span style={{ color: "#D90000" }}>Instagram reels downloader</span> is coming soon!  
    {/* <button 
        onClick={() => window.location.href = "http://localhost:3000/insta-reels-downloader"} 
        style={{
            marginLeft: "15px",
            backgroundColor: "#D90000", 
            color: "#FFF",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            fontSize: "16px",
            borderRadius: "5px"
        }}
    >
        Try Now
    </button> */}

    {/* Add to Home Screen Button */}
    {navigator.userAgent.includes("iPhone") && navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("CriOS") && (
        <button 
            onClick={() => alert("To add this app to your Home Screen:\n1. Tap the 'Share' icon (box with arrow up) in Safari.\n2. Scroll down and select 'Add to Home Screen'.")}
            style={{
                marginLeft: "15px",
                backgroundColor: "#008000", 
                color: "#FFF",
                border: "none",
                padding: "10px 15px",
                cursor: "pointer",
                fontSize: "16px",
                borderRadius: "5px"
            }}
        >
            📲 Add our website to Home Screen
        </button>
    )}
</div>
            <h1><center>Snapchat Viewer</center></h1>
            <MainContainer>
                <Content>
                    <Container>
                        {/* Existing Content */}
                        <h2>Download Snapchat Stories Easily</h2>
                        <p>Ready to get started? Enter the Snapchat username below and instantly access downloadable stories and highlights.</p>
                        <Input
                            type="text"
                            placeholder="Enter Snapchat username without @"
                            value={username}
                            onChange={handleInputChange}
                        />
                        <SearchButton onClick={handleSearch}>Search</SearchButton>
                        {loading && <LoadingText>Loading... please wait</LoadingText>}
                        
                        <h1>About Snapchat Downloader Tool</h1>
                        <p>
                            Welcome to the Snapchat Story Downloader. Our tool allows you to easily download and view Snapchat stories 
                            and highlights from any public account. Whether you want to archive a special moment or keep a collection 
                            of your favorite content, our downloader helps you capture it all with just a few clicks.
                        </p>
                        <p>
                            Simply enter the username of the Snapchat account below, and you'll be able to download stories or highlights 
                            directly to your device.
                        </p>

                        {/* Expanded Content */}
                        <h3>How to Use the Snapchat Downloader</h3>
                        <p>
                            1. Enter the Snapchat username without the '@' symbol in the field below.<br />
                            2. Click "Search" to fetch stories and highlights.<br />
                            3. Select the stories or highlights you want to download.<br />
                            4. Save them to your device and enjoy!
                        </p>

                        <h3>Why Use Our Snapchat Downloader?</h3>
                        <p>
                            Our Snapchat Viewer is fast, reliable, and easy to use. No need to install any software or create an account. 
                            Just enter the Snapchat username, and you'll have the stories or highlights ready to download in seconds. 
                            Perfect for capturing memories, creating collections, or keeping track of your favorite content creators.
                        </p>

                        {/* Additional Content for SEO */}
                        <h3>Features and Benefits</h3>
                        <ul>
                            <li><strong>High-Quality Downloads:</strong> We deliver Snapchat stories and highlights in their original quality to ensure no details are lost. Use the tool responsibly and with story creator's consent.</li>
                            <li><strong>Privacy and Security:</strong> Our service respects privacy and ensures secure downloads. No data is stored, and all sessions are private.</li>
                            <li><strong>Fast Access to Stories:</strong> Enjoy quick access to Snapchat stories and highlights without any lengthy wait times.</li>
                            <li><strong>Universal Compatibility:</strong> Our tool works on all devices – mobile, desktop, and tablets – for a seamless experience.</li>
                        </ul>

                        <h3>Who Can Benefit from Snapchat Downloader Tool?</h3>
                        <p>This Snapchat Downloader is ideal for a wide range of users:</p>
                        <ul>
                            <li><strong>Memory Keepers:</strong> Save and revisit cherished moments from friends and family.</li>
                            <li><strong>Fans of Influencers:</strong> Download stories from your favorite influencers to keep them accessible.</li>
                            <li><strong>Content Creators:</strong> Backup your own stories and highlights for future use and content management.</li>
                        </ul>

                        <h3>Frequently Asked Questions (FAQ)</h3>
                        <h4>Is downloading Snapchat stories legal?</h4>
                        <p>Yes, as long as you have the content creator’s permission. Always respect user privacy and Snapchat's terms and condition.</p>

                        <h4>Does this work with private accounts?</h4>
                        <p>No, our tool only works with public accounts to adhere to privacy guidelines. You can only download stories from profiles you have access to.</p>

                        <h4>Is my information secure?</h4>
                        <p>Absolutely. We prioritize user privacy and do not store any personal information or data related to downloads.</p>

                        <h4>Are there any limits on downloads?</h4>
                        <p>No, you can download stories as long as you've the consent or if the stories are yours. Please use the tool responsibly.</p>

                        <h3>Why Download Snapchat Stories?</h3>
                        <p>
                            Snapchat stories offer a unique, ephemeral way to share moments, but sometimes, you may want to keep those memories longer. 
                            Our Snapchat Story Downloader is perfect for saving these moments so you can look back on them whenever you want.
                        </p>

                        <h3>Save Your Favorite Memories Now</h3>
                        <p>
                            Our tool makes it easy to preserve memories, create collections, and access the content you love at any time. Start now and enjoy a fast, secure, and reliable downloading experience.
                        </p>
                    </Container>
                </Content>
                <Footer />
            </MainContainer>
        </>
    );
}

export default Home;
